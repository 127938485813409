/**
 * 字典管理接口
 */
import axiosApi from '@/service/modules/axios-api'

// 查询字典列表
export function listDictGroup(params) {
  return axiosApi.get('/dictGroup/page', params)
}

// 查询全部字典列表
export function listAllDictGroup() {
  return axiosApi.get('/dictGroup/page')
}

// 查询字典详细
export function getDictGroup(params) {
  return axiosApi.get('/dictGroup/getById', params)
}

// 新增字典
export function addDictGroup(data) {
  return axiosApi.post('/dictGroup/add', data)
}

// 修改字典
export function updateDictGroup(data) {
  return axiosApi.post('/dictGroup/edit', data)
}

// 删除字典
export function delDictGroup(data) {
  return axiosApi.post('/dictGroup/remove', data)
}
